import "./App.css";
import React, { Component } from "react";
// import { useState } from "react";
// import { create } from "ipfs-http-client";
import { Buffer } from "buffer";

import Web3 from "web3";
import { EVIDENCE_LOG_ABI, EVIDENCE_LOG_CONTRACT } from "./ConfigEvidenceLog";
import EvidenceLog from "./EvidenceLog";
import ContentLoader from "../abis/ContentLoader.json";
// import ClaimManager from '../abis/ClaimManager.json'
// import EvidenceManagement from '../abis/EvidenceManagement.json'

import Navbar from "./Navbar";
// import GutTop from "./GutTop";
import GutBc from "./GutBc";
// import Main from './Main'
// import Gut1 from "./Gut1";
// import Gut2 from "./Gut2";
// import Gut3 from "./Gut3";
// import Gut4 from "./Gut4";
// import Gut5 from "./Gut5";
// import Gut6 from "./Gut6";
// import Gut7 from "./Gut7";
// import Gut8 from "./Gut8";
// import Gut9 from "./Gut9";
// import GutIcons from "./GutIcons";
import GutBot from "./GutBot";
// import GutIPFSInfo0 from "./GutIPFSInfo0";
// import GutIPFSInfo1 from "./GutIPFSInfo1";
// import GutIPFSInfo2 from "./GutIPFSInfo2";
// import GutIPFSInfo3 from "./GutIPFSInfo3";
// import GutSimpleAirdrop from "./GutSimpleAirdrop";
// import GutWeb3vmInfo from "./GutWeb3vmInfo";
import Footer from "./Footer";
// import Iframe from "react-iframe";
// import Jumbotron from 'react-bootstrap/Jumbotron'
// import {
//   Row,
//   Col,
// } from "react-bootstrap";
import HeroZero from "./HeroZero.js";

const projectId =  process.env.IPFS_PROJECT_ID
const projectSecret = process.env.IPFS_PROJECT_SECRET

// const projectId = "2DBM8M1VMImQ5vl4IQxYQzI8uKg";
// const projectSecret = "f34bbedac4f01e7e79a9c99d04ba08cf";

const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

// const client = create({
//   host: "ipfs.infura.io",
//   port: 5001,
//   protocol: "https",
//   apiPath: "/api/v0",
//   headers: {
//     authorization: auth,
//   },
// });

const ipfsClient = require("ipfs-http-client");

// // ORIG
// const ipfs = ipfsClient({
//   host: "ipfs.infura.io",
//   port: 5001,
//   protocol: "https",
// }); // leaving out the arguments will default to these values

// WIP
const ipfs = ipfsClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  apiPath: "/api/v0",
  headers: {
    authorization: auth,
  },
}); // leaving out the arguments will default to these values

class App extends Component {
  async componentWillMount() {
    await this.loadWeb3();
    await this.loadBlockchainData();
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Web3 browser not detected. Install Metamask extension or try a different browser."
      );
    }
  }

  async loadBlockchainData() {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");

    // Load account
    const accounts = await web3.eth.getAccounts();
    this.setState({ account: accounts[0] });
    console.log(accounts);

    
    const evidenceLog = new web3.eth.Contract(
      EVIDENCE_LOG_ABI,
      EVIDENCE_LOG_CONTRACT
    );
    this.setState({ evidenceLog });

    const loggedArticleCount = await evidenceLog.methods
      .loggedArticleCount()
      .call();
    this.setState({ loggedArticleCount });
    for (var i = 1; i <= loggedArticleCount; i++) {
      const loggedArticleOfEvidence = await evidenceLog.methods
        .loggedArticlesOfEvidence(i)
        .call();
      this.setState({
        loggedArticlesOfEvidence: [
          ...this.state.loggedArticlesOfEvidence,
          loggedArticleOfEvidence,
        ],
      });
    }
    this.setState({ loading: false });

    // Get the network
    const networkId = await web3.eth.net.getId();
    console.log("network id: " + networkId);

    // Get from sol compiled json
    const networkData = ContentLoader.networks[networkId];
    console.log(networkData);

    // Get the smart contract
    if (networkData) {
      const contract = new web3.eth.Contract(
        ContentLoader.abi,
        networkData.address
      );
      this.setState({ contract });
      console.log(contract);

      // Get the content hash
      const contentHash = await contract.methods.getContent().call();
      this.setState({ contentHash });
      console.log(
        "The current content hash is: https://freeweb3.infura-ipfs.io/ipfs/" +
          contentHash
      );

      // Get the transaction hash
      const transactionHash = await web3.eth.getTransaction({});
      this.setState({ transactionHash });
      console.log("transaction hash: " + transactionHash);
    } else {
      window.alert("Smart contract not deployed to detected network.");
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      // account: '',
      loggedArticleCount: 0,
      loggedArticlesOfEvidence: [],
      loading: true,
      contentHash: "",
      transactionHash: null,
      contract: null,
      web3: null,
      buffer: null,
      networkId: null,
      account: null,
    };

    this.addEvidence = this.addEvidence.bind(this);
    this.toggleCompleted = this.toggleCompleted.bind(this);
  }

  addEvidence(evidenceDescription) {
    this.setState({ loading: true });
    this.state.evidenceLog.methods
      .addEvidence(evidenceDescription)
      .send({ from: this.state.account })
      .once("receipt", (receipt) => {
        this.setState({ loading: false });
      });
  }

  toggleCompleted(loggedArticleOfEvidenceId) {
    this.setState({ loading: true });
    this.state.evidenceLog.methods
      .toggleCompleted(loggedArticleOfEvidenceId)
      .send({ from: this.state.account })
      .once("receipt", (receipt) => {
        this.setState({ loading: false });
      });
  }

  captureFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) });
      console.log("buffer", this.state.buffer);
    };
  };

  onSubmit = (event) => {
    event.preventDefault();
    console.log(
      "Congratulations. Your buffered file was distributed to a world-wide system of public nodes. You will need to complete this process to memorialize the location on the Ethereum blockchain by signing the transaction with your Web3 account. You can install a Web3 browser plugin for Chrome Opera Firefox and Brave browsers if one does not automatically pop into your screen after you pressed submit. Metamask is a plugin that makes signing transactions easy."
    );
    ipfs.add(this.state.buffer, (error, result) => {
      console.log(
        "The result of your file upload can be found at https://freeweb3.infura-ipfs.io/ipfs/<THE-FOLLOWING-CONTENT-HASH-HERE>",
        result
      );
      if (error) {
        console.error(error);
        return;
      }
      this.state.contract.methods
        .setContent(result[0].hash)
        .send({ from: this.state.account })
        .then((r) => {
          return this.setState({ contentHash: result[0].hash });
        });
    });
  };

  render() {
    return (



      
      <div>
        <Navbar account={this.state.account} />
        {/* ---- */}

        <div>
          <GutBc account={this.state.account} />
        </div>
        {/* ---- */}

        {/* <div>
          <GutTop account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutSimpleAirdrop account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut4 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut6 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut5 account={this.state.account} />
        </div> */}
        {/* ---- */}

        <div class="container2 mb-5">
          <div id="ipfseth"></div>

          <section class="call-to-action text-white text-center mb-1">
            <HeroZero />

            <div class="row">
              <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
                <p className="bigtextgold">
                  <strong>Select and Submit Content</strong>
                </p>

                {/* <a
                  href="#magicEncryption"
                  class="mr-2 btn2 btn2-xl btn-gradient-orange btn-glow animated mb-5"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="1.8s"
                >
                  More Info on Encryption
                </a> */}

                <p class="textdesc mt-5">
                  <emwhite>
                    <small>
                      <emred>
                        <strong>DO NOT</strong>
                      </emred>{" "}
                      submit an unencrypted file unless you intend your file to
                      be publicly accessed and viewed.
                    </small>
                  </emwhite>
                </p>
                {/* <p>
                  This text is just a place holder until the copy writers step
                  in ......
                </p> */}

                <p className="bigtextlime">
                  <strong>Browse and select a file now.</strong>
                </p>

                <form onSubmit={this.onSubmit}>
                  <input type="file" onChange={this.captureFile} />
                  <input
                    type="submit"
                    class="btn2 btn-lg btn2-primary"
                    value="Submit Content File"
                  />
                </form>
              </div>
            </div>
          </section>

          <main role="main" className="col-lg-12 d-flex text-center">
            <div class="col-lg-12 mt-5 mb-5">
              <h3 class="my-1 text-white">
                Web3vm most current file:<hr></hr>
              </h3>

              <div className="content mr-auto ml-auto">
                <a
                  href="#tidbits-aboutthelivecontent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="justify-content-center text-white">
                    <a href="#more-info">
                      <img
                        class="img-fluid"
                        src={`https://freeweb3.infura-ipfs.io/ipfs/${this.state.contentHash}`}
                        alt="The Content can be found at the following link."
                      ></img>
                    </a>
                  </div>
                </a>

                <div className="justify-content-center text-warning">
                  https://freeweb3.infura-ipfs.io/ipfs/
                  <span id="contentHash">
                    <large>{this.state.contentHash}</large>
                  </span>
                </div>

                <div className="justify-content-center text-danger">
                  <small>
                    "Right Click", "Inspect Element", "Console" to view the
                    transaction hash and details.{" "}
                  </small>
                  <span id="transactionHash">
                    <small>{this.state.transactionHash}</small>
                  </span>
                </div>
              </div>

              <div id="magic"></div>
            </div>

            <hr></hr>
          </main>
        </div>

        {/* <div>
          <Gut7 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut8 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut9 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut3 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutIcons account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <Gut1 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div className="row mr-2">
          <div className="col-lg-12 d-flex justify-content-center">
            <div className="bg-light pt-1 pb-1 ml-10 mt-2">
              <Iframe
                url="https://sm.x5engine.com"
                width="1200px"
                height="1000px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
              />
            </div>
          </div>
        </div> */}

        {/* <div>
          <Gut2 account={this.state.account} />
        </div> */}
        {/* ---- */}

        <div>
          <GutBot account={this.state.account} />
        </div>
        {/* ---- */}

        <div>
          <div className="container-fluid">
            <div className="row">
              <main
                role="main"
                className="col-lg-12 d-flex justify-content-center"
              >
                {this.state.loading ? (
                  <div evidenceId="loader" className="text-center">
                    <p className="text-center text">Loading...</p>
                  </div>
                ) : (
                  <EvidenceLog
                    loggedArticlesOfEvidence={
                      this.state.loggedArticlesOfEvidence
                    }
                    addEvidence={this.addEvidence}
                    toggleCompleted={this.toggleCompleted}
                  />
                )}
              </main>

              <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
                <hr></hr>

                <p> Logged Evidence Count: {this.state.loggedArticleCount}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <GutWeb3vmInfo account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutIPFSInfo0 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutIPFSInfo1 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutIPFSInfo2 account={this.state.account} />
        </div> */}
        {/* ---- */}

        {/* <div>
          <GutIPFSInfo3 account={this.state.account} />
        </div> */}
        {/* ---- */}

        <div>
          <Footer account={this.state.account} />
        </div>
        {/* ---- */}
      </div>
      /* ---- */
    );
  }
}

export default App;
