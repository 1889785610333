import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Navbar extends Component {

render() {
  return (
    <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap shadow mb-5">

<div class="container">

          { this.props.account
            ? <img
              className='ml-1 rounded-5'
              width='50'
              height='50'
              src={makeBlockie(this.props.account, 50).toString()} alt="AcctIconImage"/>
            : <span></span>
          }

          { this.props.account
            ? <img
              className='ml-1 rounded-5'
              width='50'
              height='50'
              src={`data:image/png;base64,${new Identicon(this.props.account, 50).toString()}`} alt="AcctIconImage"/>
            : <span></span>
          }



<small className="text-white">Account: <span id="account">{this.props.account}</span></small>

          <a class="navbar-brand" href="index.html">Legal dApps on Web3vm</a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">



         <ul class="navbar-nav ml-auto">

         <li className="nav-item active">
            <a className="nav-link" href="https://github.com/worldlaw">WorldLaw
          <span className="sr-only">(current)<small>.</small></span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">Web3vm</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">Onelaw.us</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">Swag0x</a>
          </li>



        <li class="nav-item">
          <a class="nav-link" href="web3vm-step-by-step.html">Step by Step – Process a Transaction</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="web3vm-content-management.html">Content Management</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="web3vm-encrypt-and-submit.html">Encrypt & Submit Records</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownPortfolio" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Web3vm Available Services
          </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownPortfolio">
          <a class="dropdown-item" href="web3vm-syndicate-oracles.html">Syndicate Oracles – Proving Data Reliability</a>
          <a class="dropdown-item" href="web3vm-event-recs-indexing.html">Indexing and Retrieving Records</a>
          <a class="dropdown-item" href="web3vm-system-use-pawns.html">System Use Pawns</a>
          <a class="dropdown-item" href="web3vm-investigative-tools.html">Investigative Tools</a>
          <a class="dropdown-item" href="web3vm-quick-access.html">Web3vm Quick Access Protocols</a>
    </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
Choose a Service
          </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
          <a class="dropdown-item" href="web3vm-event-recs-indexing-encrypt-upload.html">Encrypting and Recording Events</a>
          <a class="dropdown-item" href="web3vm-legal-protocols.html">Web3vm Legal Protocols – WorldLaw</a>
          <a class="dropdown-item" href="web3vm-corp-governance.html">Corporate Governance - Shareholder Manangement and Proxy Voting</a>
    </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#more-info" id="navbarDropdownBlog" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
More Info - How To
          </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownBlog">
          <a class="dropdown-item" href="web3vm-training-protocols.html">Typical Use Case</a>
          <a class="dropdown-item" href="web3vm-directory.html">Directory of Web3 Services</a>
          <a class="dropdown-item" href="web3vm-more-info-faq.html">More Info & FAQ</a>
          <a class="dropdown-item" href="web3vm-site-map.html">Site Map - Home</a>
          <a class="dropdown-item" href="web3vm-transaction-fees.html">Transaction Fees</a>
    </div>
        </li>
        </ul>
    </div>
    </div>
</nav>
);
}
}


export default Navbar;
