import React, { Component } from "react";
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-center bg-light">


        <div className="container1">
          <div className="row text-center ml-2 mr-2">
            <div className="col-lg-12 h-100 text-center text-lg-center my-auto">
              {/* <ul className="list-inline mb-2">
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">WorldLaw</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Users</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Bluefin</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Gumshoe</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Proofer</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Tidbits</a>
                </li>
                <li className="list-inline-item">&sdot;</li>
                <li className="list-inline-item">
                  <a href="https://github.com/worldlaw">Judiciary</a>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-12 h-100 text-center text-lg-center my-auto">
              {/* <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-twitter-square fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-github fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-bitcoin fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fab fa-ethereum fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-gas-pump fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-dice-d6 fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-upload fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-file-download fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-user-tie fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-shield-alt fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item mr-3">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-landmark fa-2x fa-fw "></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="wl-more-info-faq.html">
                    <i className="fas fa-sliders-h fa-2x fa-fw "></i>
                  </a>
                </li>
              </ul> */}


<section class="about section-padding" id="about">
          <div class="container-fluid">
            <div class="container">
              <div class="heading text-center">
                <div
                  class="animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.3s"
                >
                  <h6 class="sub-title">Disclaimer</h6>
                  <h2 class="title">Disclaimer</h2>
                </div>

                <p
                  class="content-desc animated"
                  data-animation="fadeInUpShorter"
                  data-animation-delay="0.4s"
                >
                  Web3vm is a Web3 Legal Engineering (W3LE) product. It is not a law firm and is not intended to provide legal advice because legal advice should only be provided by a licensed attorney. Unless otherwise stated in writing, all conversations and communications with any Web3vm protocol that takes place in any public, semi-public communication channel, or any online forum (e.g. Telegram, Discord, Twitter, Voxels etc ) is not considered legal advice and should be viewed as educational based entertainment. THIS SITE COLLECTS NOTHING from the user, It only provides a way to pass information to a public content provider nodes (IPFS) and a public blockchain (Ethereum Mainchain). 
                </p>
              </div>
            </div>
          </div>
        </section>
        
              <div className="textdesc text-center mt-3">
                <emblack>
                  <strong>
                    Copyright &copy; 2023 - Web3 Legal Engineering
                  </strong>
                </emblack>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
